<template>
  <div>
    <v-row
      v-for="(item, index) in cart.products"
      :key="`${item.id}-${index}`"
      :class="'rounded mb-2 pb-4 pt-2 d-flex justify-content-around cartviewprodcard align-items-center position-relative' + (index > 0 ? ' border-top' : '')"
    >
      <v-col class="col-quantity ml-2 p-0">
        <v-icon
          @click="
            updateItemQuantity(index, (item.quantity - 1), item)
          "
          small
          dense
          color="primary"
          class="mr-1 outlined"
          >mdi-minus</v-icon
        >
        <span class="text-caption">{{
          item.quantity.toString().padStart(2, ' ')
        }}</span>
        <v-icon
          @click="
            updateItemQuantity(index, (item.quantity + 1), item)
          "
          small
          dense
          color="primary"
          class="ml-1"
          >mdi-plus</v-icon
        >
      </v-col>
      <v-col class="mx-1 mt-1 p-0 text-truncate" @click="showDialog(index, item)">
        <div class="text-subtitle-2">
          {{ item.name }}
          <v-chip
            class="ml-1"
            color="success"
            x-small
            v-if="item.isTakeaway"
          >TA</v-chip>
          <v-chip
            class="ml-1"
            color="error"
            x-small
            v-if="item.isServeLater"
          >SL</v-chip>
        </div>
        <small class="item-size teal--text p-0 m-0 mt-3 ml-1">
          <v-icon size="12" dense color="#00897B" class="mr-1 mb-1">mdi-food-variant</v-icon>
          {{ item.size.name }}
          <br/>
        </small>
        <small class="addon-item light-blue--text p-0 m-0 mt-3 ml-1" v-if="item.addons && item.addons.length > 0">
          <v-icon size="12" dense color="#1976D2" class="mr-1 mb-1">mdi-food-outline</v-icon>
          {{ displayAddonItem(item) }}
          <br/>
        </small>
        <small class="special-remarks grey--text text--darken-1 font-italic font-weight-600 ml-1" v-if="item.specialRemarks">
          <v-icon x-small dense color="#78909c" class="mr-1 mt-1">mdi-format-quote-open-outline</v-icon><span class="mt-2">{{ item.specialRemarks }}</span><v-icon x-small dense color="#78909c" class="ml-1 mt-1">mdi-format-quote-close-outline</v-icon>
        </small>
      </v-col>
      <v-col class="col-price text-right mr-2 p-0">
        <div class="font-weight-5 text-caption">
          {{ $n(item.total, 'currency', 'en-MY') }}
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="confirmDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          
        </v-card-title>
        <v-card-text>
          Are you sure want to remove this item?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="font-weight-6"
            small
            text
            plain
            @click="responseConfirmation(false)"
          >
            Cancel
          </v-btn>
          <v-btn
            color="grey lighten-1"
            x-small
            text
            plain
            @click="responseConfirmation(true)"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <OrderModal
      :product="product"
      :opendialog="opendialog"
      v-on:modal-closed="handleModalClosed($event)"
      v-if="opendialog"
    >
    </OrderModal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import OrderModal from '../Menu/OrderModal';

export default {
  name: 'CartProductCard',
  props: ['index', 'list'],
  components: {
    OrderModal,
  },
  data() {
    return {
      product: {},
      opendialog: false,
      confirmDialog: false,
      resolve: null,
    }
  },
  computed: {
    ...mapState('cart', ['cart', 'service']),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapState('product', {
      products: (state) => state.products,
    }),
  },
  methods: {
    ...mapActions('product', [
      'getProducts',
    ]),
    ...mapMutations('cart', [
      'UPDATE_CART_ITEM_QUANTITY',
      'REMOVE_CART_ITEM',
      'SET_ITEM_DETAILS',
      'SET_ITEM_SIZE',
      'SET_ITEM_QUANTITY',
      'SET_ITEM_PRICE',
      'SET_ITEM_DISCOUNT',
      'SET_ITEM_TAKEAWAY',
      'SET_ITEM_SERVE_LATER',
      'SET_ITEM_SPECIAL_REMARKS',
      'SET_ITEM_ADDON',
      'SET_ITEM_UPDATE_INDEX',
    ]),
    async updateItemQuantity(index, quantity, item) {
      if (quantity > 0) {
        this.$gtag.event('update_item_quantity', {
          'event_category': 'engagement',
          'event_label': 'Update Product Quantity',
          'event_value': `${item.name} (${item.id}) x${quantity}`,
        });
        
        this.UPDATE_CART_ITEM_QUANTITY({
          index,
          id: item.id,
          quantity,
          total:
            (item.total / item.quantity) * quantity,
        });
      } else {
        const res = await this.promptConfirmation();
        if (res) {
          this.$gtag.event('remove_from_cart', {
            'event_category': 'engagement',
            'event_label': 'Remove Product',
            'items': [{
              id: item.id,
              name: item.name,
              price: item.price,
              quantity,
              size: item.size.name,
            }],
          });

          this.REMOVE_CART_ITEM({
            index,
            id: item.id,
          });
        }
        this.confirmDialog = false;
      }

      this.$emit('cart-updated');
    },
    promptConfirmation() {
      this.confirmDialog = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    responseConfirmation(answer) {
      this.resolve(answer);
    },
    displayAddonItem(item) {
      return item.addons?.map(i => i.items.map(a => a.name))?.flat().join(', ');
    },
    handleModalClosed(updated) {
      this.opendialog = false;

      if (updated) {
        this.$emit('cart-updated');
      }
    },
    showDialog(index, item) {
      this.product = this.products.find(product => product.item_id === item.id);
      this.opendialog = true;
      window.isDialogVisible = true;

      this.$gtag.event('view_item', {
        'event_category': 'navigation',
        'event_label': 'View Product (Checkout)',
        'items': [{
          id: item.id,
          name: item.name,
          price: item.price,
        }],
      });

      this.SET_ITEM_DETAILS(item);
      this.SET_ITEM_SIZE(item.size);
      this.SET_ITEM_QUANTITY(item.quantity);
      this.SET_ITEM_PRICE(item.price);
      this.SET_ITEM_TAKEAWAY(item.isTakeaway);
      this.SET_ITEM_SERVE_LATER(item.isServeLater);
      this.SET_ITEM_SPECIAL_REMARKS(item.specialRemarks);
      
      if (item.discount) {
        this.SET_ITEM_DISCOUNT(item.discount);
      }

      this.SET_ITEM_ADDON(item.addons);
      this.SET_ITEM_UPDATE_INDEX(index);
    },
  },
  async mounted() {

  }
};
</script>

<style scoped>
.cartviewprodcard h6 {
  font-size: 13px;
  font-weight: 700;
  color: black !important;
}

.v-application .text-subtitle-2 {
  font-size: 12px !important;
  font-weight: 600;
  color: #FF6347;
}

.special-remarks {
  font-size: 10px !important;
}

.special-remarks span {
  font-weight: 500;
  position: relative;
  top: 5px;
}

.special-remarks .v-icon {
  font-size: 9px !important;
}

.col-quantity {
  max-width: 60px;
}

.col-price {
  max-width: 70px;
}

.item-size,
.addon-item {
  font-size: 10px;
  font-weight: 600;
}

.item-action-sheet {
  left: 50px;
  z-index: 3;
}

.v-btn {
  padding: 0px !important;
  min-width: 28px !important;
}

.v-btn::before {
  background-color: #ffffff;
}

.v-btn--floating {
  position: relative;
}
</style>
